export const geneNames = [
  'ageDegrade',
  'hungerDegrade',
  'mutateChance',
  'matingRadius',
  'reproduceFoodNeeded',
  'reproduceFoodGiven',
  'eatingEfficiency',
  'movingSpeed',
  'reproductionCooldown',
  'reproductionAgeNeeded',
  'eatingSpeed',
  'colorHue',
  'colorLightness'
];